.container {
  background-image: url('../../assets/icons/Foto/HeaderImage2.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.logoContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -80%);
  border-radius: 100%;
  width: 350px;
  height: 350px;
  box-shadow: 0px 0px 0px 2000px rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
}
.interaction {
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%);
  min-width: 850px;
}
.interaction > p {
  font-size: 90px;
  font-weight: 300;
  color: #fff;
  padding: 0;
  margin: 0;
}
.interaction > button {
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #83c2b4;
  border: none;
  font-size: 20px;
  margin-top: 30px;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .interaction {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%);
    min-width: 260px;
  }
  .interaction > p {
    font-size: 42px;
    font-weight: 300;
  }
  .logoContainer {
    width: 200px;
    height: 200px;
  }
  .logoContainer > div > svg {
    width: 80%;
  }
}
