.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  background-color: #83c2b4;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 100;
}

.list {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.listItem {
  list-style-type: none;
  font-size: 16px;
  margin-left: 30px;
}
.listItem:first-of-type {
  margin-left: 0;
}
.listItem > a {
  text-decoration: none;
  color: #181818;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.listItem > a > span {
  margin-left: 10px;
}

@media screen and (max-width: 1200px) {
  .listItem > a > span {
    display: none;
  }
}
