.container {
  width: 100%;
}
.offersContainer {
  background-color: #f9f9f9;
  width: 100%;
  padding: 40px 20px 60px 20px;
}
.entireMonthsOffers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}
.monthOffer {
  display: flex;
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 270px;
}
.monthName {
  border-bottom: 2px solid #83c2b4;
  color: #181818;
  padding: 20px 20px;
  font-size: 26px;
  font-weight: 300;
}
.monthPrice {
  padding: 30px;
  font-size: 30px;
  font-weight: 600;
}
.otherDatesOffers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.datesOffer {
  display: flex;
  flex-direction: column;
}
.datesOfferPeriod {
  font-size: 16px;
}

.datesOfferPrice {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
}

.discountByAgreement {
  font-size: 16px;
  color: #707070;
  margin-top: 40px;
  padding-bottom: 30px;
}

.cleaningFee {
  font-weight: 900;
  margin-top: 50px;
}

.discounts {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.discount {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.discountNights {
  font-size: 16px;
}
.discountValue {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
}

.bookNowContainer {
  background-color: #fff;
  padding: 40px 20px 100px 20px;
}

.content {
  max-width: 900px;
  margin: auto;
  margin-top: 60px;
  border: none;
}
.heading {
  font-size: 48px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #83c2b4;
  padding: 0;
  margin: 0;
}
.divider {
  width: 400px;
  margin: 20px auto 0 auto;

  border: solid 1px #ecebeb;
}
.message {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #181818;
  margin-top: 30px;
}

.arrow {
  position: absolute;
  right: -5px;
  top: 30px;
}
.counters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #181818;
  padding: 10px 50px;
}

.counterContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.counterContainer > .counterBody {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.counterContainer > .counterBody > span {
  font-size: 1rem;
  color: #181818;
  padding: 0 10px;
}
.counterContainer > .counterBody > div > svg {
  cursor: pointer;
}
.counterContainer > .counterBody > div > svg:hover {
  transform: scale(1.4);
}

.counterContainer > .counterHeading > span {
  color: rgba(0, 0, 0, 0.54);
}
.priceContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.price {
  margin-top: 30px;
}
.price > .label,
.amount {
  font-size: 34px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #83c2b4;
}
.price > .amount {
  color: #181818;
  margin-left: 10px;
}
.price > .discountApplied {
  color: #707070;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  display: block;
}
.priceDesc {
  color: #707070;
  font-size: 14px;
  text-align: start;
  width: 400px;
  margin: 40px auto;
}
.priceDescHeading {
  text-align: center;
}
.times {
  text-align: center;
}

.sendQuote {
  width: 170px;
  height: 50px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #181818;
  border-radius: 8px;
  background-color: #83c2b4;
  border: none;
  margin-top: 20px;
}
@media screen and (max-width: 1199px) {
  .divider {
    width: 80%;
  }
  .arrow {
    display: none;
  }
  .offersContainer {
    background-color: #f9f9f9;
    width: 100%;
    padding: 40px 0 60px 0;
  }
  .price {
    margin-top: 15px;
  }

  .entireMonthsOffers {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
  }
  .monthOffer {
    display: flex;
    padding: 10px;
    margin-top: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  .monthName {
    border-bottom: 2px solid #83c2b4;
    color: #181818;
    padding: 20px 20px;
    font-size: 22px;
    font-weight: 300;
  }
  .monthPrice {
    padding: 30px;
    font-size: 26px;
    font-weight: 600;
  }
  .otherDatesOffers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    max-width: 100%;
  }
  .datesOffer {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .datesOfferPeriod {
    font-size: 14px;
  }
  .discountByAgreement {
    font-size: 14px;
    color: #707070;
    margin-top: 20px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .datesOfferPrice {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
  }
  .cleaningFee {
    font-weight: 900;
    font-size: 14px;
    margin-top: 50px;
  }

  .discounts {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    padding: 0 20px;
    max-width: 100%;
    flex-wrap: wrap;
  }
  .discount {
    margin-bottom: 10px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .discountNights {
    font-size: 14px;
  }
  .discountValue {
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
  }
  .priceDesc {
    width: 300px;
    font-size: 12px;
  }
}

.discount:first-of-type {
  margin-left: 0;
}
.campNotOpen {
  color: red;
  font-weight: 600;
}
