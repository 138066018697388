.container {
  padding: 50px 0;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.heading {
  color: #83c2b4;
  font-size: 48px;
  font-weight: 300;
}
.divider {
  width: 260px;
  border-top: 1px solid #ecebeb;
  margin-top: 30px;
  margin-bottom: 30px;
}
.text {
  line-height: 28px;
  font-size: 16px;
  color: #181818;
  max-width: 770px;
}
@media screen and (max-width: 1200px) {
  .heading {
    font-size: 34px;
  }
  .text {
    line-height: 24px;
    font-size: 14px;
    color: #181818;
    max-width: 280px;
  }
}
