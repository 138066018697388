.container {
  width: 100%;
  height: 700px;
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.containerImg {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-fit: fill;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.images {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image:first-of-type {
  margin-left: 0;
}
.imageContainer {
  width: 525px;
  height: 350px;
  margin-left: 30px;
  position: relative;
}
.currentImageContainer {
  width: 630px;
  height: 420px;
  margin-left: 30px;
  position: relative;
  cursor: pointer;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-box-shadow: 2px 2px 9px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 9px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 9px -2px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}
.imageLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.controls {
  position: absolute;
  bottom: 80px;
  left: 330px;
  display: flex;
  width: 70px;
  justify-content: space-between;
}
.controls > div > svg:hover {
  transform: scale(1.4);
  cursor: pointer;
}
.movingL {
  animation: moveL 0.15s;
}
.movingR {
  animation: moveR 0.15s;
}
.growing {
  animation: grow 0.15s;
}
.shrinking {
  animation: shrink 0.15s;
}
@keyframes moveR {
  100% {
    transform: translate(-555px, 0);
  }
}
@keyframes moveL {
  100% {
    transform: translate(555px, 0);
  }
}
@keyframes grow {
  100% {
    width: 630px;
    height: 420px;
  }
}
@keyframes shrink {
  100% {
    width: 525px;
    height: 350px;
  }
}

@media screen and (max-width: 1199px) {
  .container {
    height: 350px;
  }
  .imageContainer {
    width: 225px;
    height: 150px;
    margin-left: 15px;
  }
  .currentImageContainer {
    width: 270px;
    height: 180px;
    margin-left: 15px;
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .controls {
    position: absolute;
    bottom: 30px;
    left: 35px;
    display: flex;
    width: 70px;
    justify-content: space-between;
  }
  @keyframes grow {
    100% {
      width: 270px;
      height: 180px;
    }
  }
  @keyframes shrink {
    100% {
      width: 225px;
      height: 150px;
    }
  }

  @keyframes moveR {
    100% {
      transform: translate(-240px, 0);
    }
  }
  @keyframes moveL {
    100% {
      transform: translate(240px, 0);
    }
  }
}

.fullScreenContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fullScreenBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.fullScreenImageContainer {
  z-index: 1001;
  width: 80%;
  height: 90%;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.fullScreenImage {
  z-index: 1002;
  max-width: 90%;
  max-height: 95%;
  object-fit: contain;
  -webkit-box-shadow: 2px 2px 9px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 9px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 9px -2px rgba(0, 0, 0, 0.75);
}
.arrowRight,
.arrowLeft,
.closeIcon {
  z-index: 1002;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #83c2b4;
  border-radius: 10%;
}

.closeIcon:hover {
  transform: scale(1.1);
}
.arrowRight:hover {
  transform: scale(1.1);
}
.arrowLeft:hover {
  transform: scale(1.1);
}

.arrowLeft {
  left: 10px;
}
.arrowRight {
  right: 10px;
}
.closeIcon {
  top: 10px;
  right: 10px;
  background-color: black;
}
.numbering {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
}
