@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #181818;
}
.container {
  width: 100%;
}
html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.BookNow_container__21XqP {
  width: 100%;
}
.BookNow_offersContainer__21ugp {
  background-color: #f9f9f9;
  width: 100%;
  padding: 40px 20px 60px 20px;
}
.BookNow_entireMonthsOffers__1CV17 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 30px;
}
.BookNow_monthOffer__2on8g {
  display: -webkit-flex;
  display: flex;
  padding: 10px;
  background-color: #fff;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 270px;
}
.BookNow_monthName__2AlI- {
  border-bottom: 2px solid #83c2b4;
  color: #181818;
  padding: 20px 20px;
  font-size: 26px;
  font-weight: 300;
}
.BookNow_monthPrice__1e1IH {
  padding: 30px;
  font-size: 30px;
  font-weight: 600;
}
.BookNow_otherDatesOffers__a5MG2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 30px;
}
.BookNow_datesOffer__1sOkT {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.BookNow_datesOfferPeriod__2_kjE {
  font-size: 16px;
}

.BookNow_datesOfferPrice__RJ0ET {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
}

.BookNow_discountByAgreement__2kkoa {
  font-size: 16px;
  color: #707070;
  margin-top: 40px;
  padding-bottom: 30px;
}

.BookNow_cleaningFee__-XyOY {
  font-weight: 900;
  margin-top: 50px;
}

.BookNow_discounts__1Faza {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 30px;
}
.BookNow_discount__L3Wv0 {
  margin-left: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.BookNow_discountNights__26koV {
  font-size: 16px;
}
.BookNow_discountValue__2o8sS {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
}

.BookNow_bookNowContainer__1vkeA {
  background-color: #fff;
  padding: 40px 20px 100px 20px;
}

.BookNow_content__28zWp {
  max-width: 900px;
  margin: auto;
  margin-top: 60px;
  border: none;
}
.BookNow_heading__Adapy {
  font-size: 48px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #83c2b4;
  padding: 0;
  margin: 0;
}
.BookNow_divider__2zemc {
  width: 400px;
  margin: 20px auto 0 auto;

  border: solid 1px #ecebeb;
}
.BookNow_message__19g05 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #181818;
  margin-top: 30px;
}

.BookNow_arrow__3V5Ky {
  position: absolute;
  right: -5px;
  top: 30px;
}
.BookNow_counters__3HzTv {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  color: #181818;
  padding: 10px 50px;
}

.BookNow_counterContainer__vLLY- {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.BookNow_counterContainer__vLLY- > .BookNow_counterBody__1l6_2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 10px;
}
.BookNow_counterContainer__vLLY- > .BookNow_counterBody__1l6_2 > span {
  font-size: 1rem;
  color: #181818;
  padding: 0 10px;
}
.BookNow_counterContainer__vLLY- > .BookNow_counterBody__1l6_2 > div > svg {
  cursor: pointer;
}
.BookNow_counterContainer__vLLY- > .BookNow_counterBody__1l6_2 > div > svg:hover {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.BookNow_counterContainer__vLLY- > .BookNow_counterHeading__nxCPR > span {
  color: rgba(0, 0, 0, 0.54);
}
.BookNow_priceContainer__2Frev {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.BookNow_price__36K0F {
  margin-top: 30px;
}
.BookNow_price__36K0F > .BookNow_label__3xZ7g,
.BookNow_amount__wuKao {
  font-size: 34px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #83c2b4;
}
.BookNow_price__36K0F > .BookNow_amount__wuKao {
  color: #181818;
  margin-left: 10px;
}
.BookNow_price__36K0F > .BookNow_discountApplied__3d79o {
  color: #707070;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  display: block;
}
.BookNow_priceDesc__2AX2K {
  color: #707070;
  font-size: 14px;
  text-align: start;
  width: 400px;
  margin: 40px auto;
}
.BookNow_priceDescHeading__pHRFq {
  text-align: center;
}
.BookNow_times__1u0Ro {
  text-align: center;
}

.BookNow_sendQuote__2cV1B {
  width: 170px;
  height: 50px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #181818;
  border-radius: 8px;
  background-color: #83c2b4;
  border: none;
  margin-top: 20px;
}
@media screen and (max-width: 1199px) {
  .BookNow_divider__2zemc {
    width: 80%;
  }
  .BookNow_arrow__3V5Ky {
    display: none;
  }
  .BookNow_offersContainer__21ugp {
    background-color: #f9f9f9;
    width: 100%;
    padding: 40px 0 60px 0;
  }
  .BookNow_price__36K0F {
    margin-top: 15px;
  }

  .BookNow_entireMonthsOffers__1CV17 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 30px;
  }
  .BookNow_monthOffer__2on8g {
    display: -webkit-flex;
    display: flex;
    padding: 10px;
    margin-top: 20px;
    background-color: #fff;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 80%;
  }
  .BookNow_monthName__2AlI- {
    border-bottom: 2px solid #83c2b4;
    color: #181818;
    padding: 20px 20px;
    font-size: 22px;
    font-weight: 300;
  }
  .BookNow_monthPrice__1e1IH {
    padding: 30px;
    font-size: 26px;
    font-weight: 600;
  }
  .BookNow_otherDatesOffers__a5MG2 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 30px;
    max-width: 100%;
  }
  .BookNow_datesOffer__1sOkT {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 10px;
  }
  .BookNow_datesOfferPeriod__2_kjE {
    font-size: 14px;
  }
  .BookNow_discountByAgreement__2kkoa {
    font-size: 14px;
    color: #707070;
    margin-top: 20px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .BookNow_datesOfferPrice__RJ0ET {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
  }
  .BookNow_cleaningFee__-XyOY {
    font-weight: 900;
    font-size: 14px;
    margin-top: 50px;
  }

  .BookNow_discounts__1Faza {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 30px;
    padding: 0 20px;
    max-width: 100%;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .BookNow_discount__L3Wv0 {
    margin-bottom: 10px;
    margin-left: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }

  .BookNow_discountNights__26koV {
    font-size: 14px;
  }
  .BookNow_discountValue__2o8sS {
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
  }
  .BookNow_priceDesc__2AX2K {
    width: 300px;
    font-size: 12px;
  }
}

.BookNow_discount__L3Wv0:first-of-type {
  margin-left: 0;
}
.BookNow_campNotOpen__19gfI {
  color: red;
  font-weight: 600;
}

.ContactBar_container__355xN {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  background-color: #83c2b4;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  z-index: 100;
}

.ContactBar_list__YMcBA {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0;
}
.ContactBar_listItem__1Vh4l {
  list-style-type: none;
  font-size: 16px;
  margin-left: 30px;
}
.ContactBar_listItem__1Vh4l:first-of-type {
  margin-left: 0;
}
.ContactBar_listItem__1Vh4l > a {
  text-decoration: none;
  color: #181818;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.ContactBar_listItem__1Vh4l > a > span {
  margin-left: 10px;
}

@media screen and (max-width: 1200px) {
  .ContactBar_listItem__1Vh4l > a > span {
    display: none;
  }
}

.Footer_container__st7Zp {
  background-color: #f9f9f9;
  padding: 60px 20px;
}
.Footer_content__27StV {
  max-width: 1100px;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.Footer_footerItem__3vV4O {
  width: 20%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 10px;
}
.Footer_heading__3sCUN {
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 20px;
  margin-top: 10px;
}
.Footer_item__1aCIz {
  margin-top: 5px;
  font-size: 16px;
}
a {
  text-decoration: none;
  color: #181818;
}
.Footer_social__2L3wh {
  display: -webkit-flex;
  display: flex;
}
.Footer_social__2L3wh > a {
  margin-right: 15px;
}
@media screen and (min-width: 1200px) {
  .Footer_item__1aCIz {
    text-align: start;
  }
}
@media screen and (max-width: 1199px) {
  .Footer_container__st7Zp {
    background-color: #f9f9f9;
    width: 100%;
    padding: 0;
    padding-bottom: 100px;
  }
  .Footer_content__27StV {
    margin: auto;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
  .Footer_footerItem__3vV4O {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 30px 0;
  }
}

.Header_container__1VC87 {
  background-image: url(/static/media/HeaderImage2.d3aee2cf.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.Header_logoContainer__gaEZM {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -80%);
          transform: translate(-50%, -80%);
  border-radius: 100%;
  width: 350px;
  height: 350px;
  box-shadow: 0px 0px 0px 2000px rgba(0, 0, 0, 0.35);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.Header_interaction__25xbT {
  position: absolute;
  bottom: 15%;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  min-width: 850px;
}
.Header_interaction__25xbT > p {
  font-size: 90px;
  font-weight: 300;
  color: #fff;
  padding: 0;
  margin: 0;
}
.Header_interaction__25xbT > button {
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #83c2b4;
  border: none;
  font-size: 20px;
  margin-top: 30px;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .Header_interaction__25xbT {
    position: absolute;
    bottom: 15%;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    min-width: 260px;
  }
  .Header_interaction__25xbT > p {
    font-size: 42px;
    font-weight: 300;
  }
  .Header_logoContainer__gaEZM {
    width: 200px;
    height: 200px;
  }
  .Header_logoContainer__gaEZM > div > svg {
    width: 80%;
  }
}

.ImageSlider_container__3_D2k {
  width: 100%;
  height: 700px;
  display: -webkit-flex;
  display: flex;
  background-color: #fff;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
  position: relative;
}
.ImageSlider_containerImg__gjKJ_ {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-fit: fill;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.ImageSlider_images__1lFHM {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.ImageSlider_image__oVBVW:first-of-type {
  margin-left: 0;
}
.ImageSlider_imageContainer__JK0Sf {
  width: 525px;
  height: 350px;
  margin-left: 30px;
  position: relative;
}
.ImageSlider_currentImageContainer__2hV2O {
  width: 630px;
  height: 420px;
  margin-left: 30px;
  position: relative;
  cursor: pointer;
}
.ImageSlider_image__oVBVW {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 2px 2px 9px -2px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}
.ImageSlider_imageLoading__1DFAU {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.ImageSlider_controls__1RTfN {
  position: absolute;
  bottom: 80px;
  left: 330px;
  display: -webkit-flex;
  display: flex;
  width: 70px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.ImageSlider_controls__1RTfN > div > svg:hover {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  cursor: pointer;
}
.ImageSlider_movingL__2nxfZ {
  -webkit-animation: ImageSlider_moveL__36EII 0.15s;
          animation: ImageSlider_moveL__36EII 0.15s;
}
.ImageSlider_movingR__3VeWn {
  -webkit-animation: ImageSlider_moveR__1nJWX 0.15s;
          animation: ImageSlider_moveR__1nJWX 0.15s;
}
.ImageSlider_growing__243w6 {
  -webkit-animation: ImageSlider_grow__24jk6 0.15s;
          animation: ImageSlider_grow__24jk6 0.15s;
}
.ImageSlider_shrinking__1dG1d {
  -webkit-animation: ImageSlider_shrink__19Ojd 0.15s;
          animation: ImageSlider_shrink__19Ojd 0.15s;
}
@-webkit-keyframes ImageSlider_moveR__1nJWX {
  100% {
    -webkit-transform: translate(-555px, 0);
            transform: translate(-555px, 0);
  }
}
@keyframes ImageSlider_moveR__1nJWX {
  100% {
    -webkit-transform: translate(-555px, 0);
            transform: translate(-555px, 0);
  }
}
@-webkit-keyframes ImageSlider_moveL__36EII {
  100% {
    -webkit-transform: translate(555px, 0);
            transform: translate(555px, 0);
  }
}
@keyframes ImageSlider_moveL__36EII {
  100% {
    -webkit-transform: translate(555px, 0);
            transform: translate(555px, 0);
  }
}
@-webkit-keyframes ImageSlider_grow__24jk6 {
  100% {
    width: 630px;
    height: 420px;
  }
}
@keyframes ImageSlider_grow__24jk6 {
  100% {
    width: 630px;
    height: 420px;
  }
}
@-webkit-keyframes ImageSlider_shrink__19Ojd {
  100% {
    width: 525px;
    height: 350px;
  }
}
@keyframes ImageSlider_shrink__19Ojd {
  100% {
    width: 525px;
    height: 350px;
  }
}

@media screen and (max-width: 1199px) {
  .ImageSlider_container__3_D2k {
    height: 350px;
  }
  .ImageSlider_imageContainer__JK0Sf {
    width: 225px;
    height: 150px;
    margin-left: 15px;
  }
  .ImageSlider_currentImageContainer__2hV2O {
    width: 270px;
    height: 180px;
    margin-left: 15px;
  }
  .ImageSlider_image__oVBVW {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .ImageSlider_controls__1RTfN {
    position: absolute;
    bottom: 30px;
    left: 35px;
    display: -webkit-flex;
    display: flex;
    width: 70px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  @-webkit-keyframes ImageSlider_grow__24jk6 {
    100% {
      width: 270px;
      height: 180px;
    }
  }
  @keyframes ImageSlider_grow__24jk6 {
    100% {
      width: 270px;
      height: 180px;
    }
  }
  @-webkit-keyframes ImageSlider_shrink__19Ojd {
    100% {
      width: 225px;
      height: 150px;
    }
  }
  @keyframes ImageSlider_shrink__19Ojd {
    100% {
      width: 225px;
      height: 150px;
    }
  }

  @-webkit-keyframes ImageSlider_moveR__1nJWX {
    100% {
      -webkit-transform: translate(-240px, 0);
              transform: translate(-240px, 0);
    }
  }

  @keyframes ImageSlider_moveR__1nJWX {
    100% {
      -webkit-transform: translate(-240px, 0);
              transform: translate(-240px, 0);
    }
  }
  @-webkit-keyframes ImageSlider_moveL__36EII {
    100% {
      -webkit-transform: translate(240px, 0);
              transform: translate(240px, 0);
    }
  }
  @keyframes ImageSlider_moveL__36EII {
    100% {
      -webkit-transform: translate(240px, 0);
              transform: translate(240px, 0);
    }
  }
}

.ImageSlider_fullScreenContainer__7M4pq {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1000;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.ImageSlider_fullScreenBg__3b0f0 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.ImageSlider_fullScreenImageContainer__38DfH {
  z-index: 1001;
  width: 80%;
  height: 90%;
  z-index: 1001;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}
.ImageSlider_fullScreenImage__39wsb {
  z-index: 1002;
  max-width: 90%;
  max-height: 95%;
  object-fit: contain;
  box-shadow: 2px 2px 9px -2px rgba(0, 0, 0, 0.75);
}
.ImageSlider_arrowRight__iSiJw,
.ImageSlider_arrowLeft__2Y_UL,
.ImageSlider_closeIcon__3_XDn {
  z-index: 1002;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #83c2b4;
  border-radius: 10%;
}

.ImageSlider_closeIcon__3_XDn:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ImageSlider_arrowRight__iSiJw:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ImageSlider_arrowLeft__2Y_UL:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.ImageSlider_arrowLeft__2Y_UL {
  left: 10px;
}
.ImageSlider_arrowRight__iSiJw {
  right: 10px;
}
.ImageSlider_closeIcon__3_XDn {
  top: 10px;
  right: 10px;
  background-color: black;
}
.ImageSlider_numbering__1MN-0 {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

.Info_container__3kTEM {
  padding: 50px 0;
  background-color: #f9f9f9;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.Info_heading__2jo8e {
  color: #83c2b4;
  font-size: 48px;
  font-weight: 300;
}
.Info_divider__3OwE1 {
  width: 260px;
  border-top: 1px solid #ecebeb;
  margin-top: 30px;
  margin-bottom: 30px;
}
.Info_text__1CQ_6 {
  line-height: 28px;
  font-size: 16px;
  color: #181818;
  max-width: 770px;
}
@media screen and (max-width: 1200px) {
  .Info_heading__2jo8e {
    font-size: 34px;
  }
  .Info_text__1CQ_6 {
    line-height: 24px;
    font-size: 14px;
    color: #181818;
    max-width: 280px;
  }
}

.Navigation_container___wsPy {
  width: 100%;
  height: 65px;
  position: fixed;
  color: #fff;
  top: 40px;
  left: 0;
  z-index: 100;
}
.Navigation_navigationContainer__hSt3b {
  height: 65px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.Navigation_navigationContainerScrolling__2MfDv {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.35);
  color: #181818;
}

.Navigation_links__2Ao6a {
  display: -webkit-flex;
  display: flex;
  padding: 0;
  margin: 0;
}
.Navigation_link__12o2K {
  list-style-type: none;
  margin-left: 30px;
  cursor: pointer;
  font-size: 18px;
}
.Navigation_link__12o2K:first-of-type {
  margin-left: 0;
}

.Navigation_hamburgerContainer__2-uG5 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-top: 10px;
  padding-right: 10px;
  -webkit-align-items: center;
          align-items: center;
  z-index: 200;
}

.Navigation_mobileNavigationContainer__KlW_Y {
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 90;
  left: 0;
}
.Navigation_mobileNavLinks__wQ89d {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0;
  margin: 0;
}
.Navigation_mobileLink__1Krty {
  list-style-type: none;
  cursor: pointer;
  font-size: 18px;
  color: #181818;
  margin-top: 20px;
}

.Navigation_divider__ZPHJ- {
  width: 95%;
  border-bottom: 1px solid #ebebeb;
  margin: 30px auto;
}
@media screen and (max-width: 1200px) {
  .Navigation_desktop__1SUJv {
    display: none;
  }
  .Navigation_languageSelectContainer__Yf2oF {
    padding: 0;
    margin: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }

  .Navigation_languageSelected__33WGw::after {
    content: url(/static/media/arrow.5eb631e2.svg);
    position: absolute;
    margin-left: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .Navigation_mobile__2nII0 {
    display: none;
  }
}

