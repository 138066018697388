.container {
  background-color: #f9f9f9;
  padding: 60px 20px;
}
.content {
  max-width: 1100px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.footerItem {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
}
.heading {
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 20px;
  margin-top: 10px;
}
.item {
  margin-top: 5px;
  font-size: 16px;
}
a {
  text-decoration: none;
  color: #181818;
}
.social {
  display: flex;
}
.social > a {
  margin-right: 15px;
}
@media screen and (min-width: 1200px) {
  .item {
    text-align: start;
  }
}
@media screen and (max-width: 1199px) {
  .container {
    background-color: #f9f9f9;
    width: 100%;
    padding: 0;
    padding-bottom: 100px;
  }
  .content {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .footerItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
  }
}
