.container {
  width: 100%;
  height: 65px;
  position: fixed;
  color: #fff;
  top: 40px;
  left: 0;
  z-index: 100;
}
.navigationContainer {
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navigationContainerScrolling {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.35);
  color: #181818;
}

.links {
  display: flex;
  padding: 0;
  margin: 0;
}
.link {
  list-style-type: none;
  margin-left: 30px;
  cursor: pointer;
  font-size: 18px;
}
.link:first-of-type {
  margin-left: 0;
}

.hamburgerContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 10px;
  align-items: center;
  z-index: 200;
}

.mobileNavigationContainer {
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 90;
  left: 0;
}
.mobileNavLinks {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}
.mobileLink {
  list-style-type: none;
  cursor: pointer;
  font-size: 18px;
  color: #181818;
  margin-top: 20px;
}

.divider {
  width: 95%;
  border-bottom: 1px solid #ebebeb;
  margin: 30px auto;
}
@media screen and (max-width: 1200px) {
  .desktop {
    display: none;
  }
  .languageSelectContainer {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .languageSelected::after {
    content: url('../../assets/icons/MobileMenu/arrow.svg');
    position: absolute;
    margin-left: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .mobile {
    display: none;
  }
}
